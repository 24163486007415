import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export function unixToDate(unix: number, format = 'YYYY-MM-DD'): string {
  return dayjs.unix(unix).utc().format(format)
}

export default function FormatTime(unix: string, buffer?: number) {
  const { t } = useTranslation()
  const now = dayjs()
  const timestamp = dayjs.unix(parseInt(unix)).add(buffer ?? 0, 'minute')

  const inSeconds = now.diff(timestamp, 'second')
  const inMinutes = now.diff(timestamp, 'minute')
  const inHours = now.diff(timestamp, 'hour')
  const inDays = now.diff(timestamp, 'day')

  if (inMinutes < 1) {
    return 'recently'
  }

  if (inHours >= 24) {
    return `${inDays} ${inDays === 1 ? t('day') : t('days')} ${t('ago')}`
  } else if (inMinutes >= 60) {
    return `${inHours} ${inHours === 1 ? t('hour') : t('hours')} ${t('ago')}`
  } else if (inSeconds >= 60) {
    return `${inMinutes} ${inMinutes === 1 ? t('minute') : t('minutes')} ${t('ago')}`
  } else {
    return `${inSeconds} ${inSeconds === 1 ? t('second') : t('seconds')} ${t('ago')}`
  }
}
